import { Injectable, signal } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { Logger } from '@core/utils/logger';
import { Observable } from 'rxjs';

const logger = new Logger('AdvancedSearchService');

/**
 * Service to manage the globally used search term.
 */
@Injectable({
    providedIn: 'root',
})
export class SearchTermService {
    //TODO: Think about registering term providers to make this service reusable for different search contexts

    readonly #searchTerm = signal<string | null>(null);
    readonly #searchTerm$ = toObservable(this.#searchTerm);

    setTerm(term: string | null) {
        this.#searchTerm.set(term);
    }

    getValue(): string | null {
        return this.#searchTerm() ?? null;
    }

    valueChanges(): Observable<string | null> {
        return this.#searchTerm$;
    }
}

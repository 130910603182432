// `.env.ts` is generated by the `npm run env` command
import { LogLevel } from '@core/utils/logger';
// `npm run env` exposes environment variables as JSON for any usage you might
import { env } from '@env/.env';

export const environment = {
    name: 'staging',
    production: true,
    logLevel: LogLevel.Info,
    domain: 'fainin.dev',
    hostUrl: 'https://fainin.dev',
    shopApiUrl: 'https://fainin-dev.stellate.sh',
    baseApiUrl: 'https://api.fainin.dev',
    baseHref: '/',
    tokenMethod: 'bearer',
    strapiUrl: 'https://strapiv2.fainin.com',
    mailchimpSubscriberUrl:
        'https://fainin.us12.list-manage.com/subscribe/post-json?u=ea6d228321d874fddeb06cf40&amp;id=c79b7cfabc',
    version: env.npm_package_version + '-dev',
    defaultLanguage: 'de-DE',
    supportedLanguages: ['en-US', 'de-DE', 'uk-UA', 'ru-RU'],
    googleAnalyticsId: 'G-FGJF71824R',
    googleTagsId: 'GTM-MLFFFN8',
    reCaptchaSiteKey: '6LcnbfQbAAAAACzf2u1MbEudx3HHZ_iwmV50bJHh',
    googleDynamicMapsApiKey: 'AIzaSyAuG8H-fv9vdnSoiNzCLgXMe4HwuR3iczU',
    allowIndexing: false,
    disallowList: [],
    featureFlags: {
        product: true,
        collection: true,
        account: true,
        blog: true,
        gamification: true,
        groups: true,
    },
    faininSocketPath: '/fainin-socket',
    GoogleOauthWebId: '773283378094-vrgdm99je1p5n0pdkrp1mvslhv2uch0o.apps.googleusercontent.com',
    sumSubApi: 'https://api.sumsub.com',
    appleClientId: 'com.fainin.FaininApp.service',
    stripeKey: 'pk_test_r2WdxrXH4FJ5ywDtQ5rQ7tRU',
    googleLoginPluginName: 'Fainin-Staging',
    talkJsAppId: 'tcIFRIX6',
    keepLogs: true,
};
